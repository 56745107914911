 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="银行卡号">
				<el-input class="el_input" v-model="form.card_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用于出金">
				<el-select class="el_input" v-model="form.use_for_outcash" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用于收款">
				<el-select class="el_input" v-model="form.use_for_collection" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="银行卡号信息">
					<template slot-scope="scope">
						<div>{{scope.row.card_num}} / {{scope.row.bank_cname}}</div>
					</template>
				</el-table-column>

				<el-table-column label="用于出金" prop="use_for_outcash_text"></el-table-column>
				<el-table-column label="用于收款" prop="use_for_collection_text"></el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="bankcard_del(scope.row)" size="mini" type="text" style="color:red">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 添加弹出层 -->
		<platformBankcardAdd :is_show="add.is_show" @added="close_add_view"></platformBankcardAdd>

		<!-- 修改弹出层 -->
		<platformBankcardEdit :is_show="edit.is_show" :old_data="edit.old_data" @edited="close_edit_view"></platformBankcardEdit>

	</div>
</template>

<script>
	import platformBankcardAdd from './add.vue';
	import platformBankcardEdit from './edit.vue';
	export default {
		components:{
			platformBankcardAdd,
			platformBankcardEdit
		},
		data() {
			return {
				
				//搜索条件
				form: {
					card_num:'',//银行卡号
					use_for_outcash:'',//用于出金
					use_for_collection:'',//用于收款
					mark:'',//备注
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加界面
				add:{

					//是否显示
					is_show:0
				},

				edit:{

					//是否显示
					is_show:0,

					//旧数据
					old_data:{}
				},
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//删除
			bankcard_del(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除平台银行卡",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'finance',
								ctr:'platform_bankcard_del_admin',
								id:item.id
							},
							callback:(data)=>{

								//报告结果
								this.$my.other.msg({
									type:'success',
									str:"添加成功"
								});
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//前往添加页面
			open_add_view(){
				
				//打开界面
				this.add.is_show++;
			},
			close_add_view(){
				
				//重置
				this.add.is_show=0
				
				//刷新页面
				this.get_page_data()
			},

			//打开客户修改界面
			open_edit_view(item){

				//置入数据
				this.edit.old_data=item

				//打开界面
				this.edit.is_show++;
			},
			close_edit_view(){//
				
				//重置
				this.edit.is_show=0

				//刷新页面
				this.get_page_data()
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					card_num:'',//银行卡号
					use_for_outcash:'',//用于出金
					use_for_collection:'',//用于收款
					mark:'',//备注
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'platform_bankcard_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//是否用来出金(1:是,2:不是)
							switch(item.use_for_outcash){
								case '1':
									item.use_for_outcash_text='是';
									break;
								case '2':
									item.use_for_outcash_text='否';
									break;
							}

							//是否用来出金(1:是,2:不是)
							switch(item.use_for_collection){
								case '1':
									item.use_for_collection_text='是';
									break;
								case '2':
									item.use_for_collection_text='否';
									break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>